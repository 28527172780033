import React from "react";
import {
  Text,
} from 'grommet';
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  <Text>Login to Nada</Text>
  return <button onClick={() => loginWithRedirect()}>Log In</button>;
};

export default LoginButton;