import React from 'react';
import { Box, Heading, Text } from 'grommet';
import { useAuth0 } from "@auth0/auth0-react";

const Home = () => {
  const { user, isAuthenticated } = useAuth0();
  return (
    <Box>
      <Box  align="center" justify="center">
        <Heading margin={{ top: 'none' }}>Home Page</Heading>
      </Box>
      <Box>
        <Text>
          {isAuthenticated ? `Welcome, ${user.name}!` : 'Welcome!'}
        </Text> 
      </Box>

    </Box>
  )
}

export default Home;