import React from 'react';
import { Box,  Card, CardBody, CardHeader, CardFooter, Heading, List, Text } from 'grommet';
import { useAuth0 } from "@auth0/auth0-react";

const axios = require('axios');
const cheerio = require('cheerio');

const getStockPrice = async (symbol) => {
    try {
      const url = `https://finance.yahoo.com/quote/${symbol}`;
      const { data } = await axios.get(url);
      const $ = cheerio.load(data);
      const price = $('span[data-reactid="32"]').first().text();
      console.log(`The price of ${symbol} is ${price}`);
    } catch (error) {
      console.error(`Error fetching price for ${symbol}: ${error}`);
    }
  };

const Accounts = () => {
    const { isAuthenticated } = useAuth0();

    return (
        <Box>
            <Heading margin={{ top: 'none' }}>Account List</Heading>
            { isAuthenticated && (
                <Box>
                    <Card>
                        <CardHeader pad="medium">Questrade</CardHeader>
                        <CardBody pad="medium">Don Margin
                        <List
                            primaryKey="name"
                            secondaryKey="shares"
                            data={[
                                { name: 'BK.TO', shares: 100, cost: getStockPrice('BK.TO') },
                                { name: 'RY.TO', shares: 300, cost: getStockPrice('RY.TO') },
                                { name: 'NVDA', shares: 400, cost: getStockPrice('NVDA') },
                                { name: 'AAPL', shares: 100, cost: getStockPrice('AAPL') },
                                { name: 'SMCI', shares: 220, cost: getStockPrice('SMCI') },
                            ]}
                        />
                        </CardBody>
                        <CardFooter pad={{horizontal: "small"}} background="light-2">
                            QT
                        </CardFooter>
                    </Card>
                    <Card>
                        <CardHeader pad="medium">TD</CardHeader>
                        <CardBody pad="medium">Don Margin
                        <List
                            primaryKey="name"
                            secondaryKey="shares"
                            data={[
                                { name: 'CASH.TO', shares: 800 },
                                { name: 'ENB.TO', shares: 200 },

                            ]}
                        />
                        </CardBody>
                        <CardFooter pad={{horizontal: "small"}} background="light-2">
                            TD
                        </CardFooter>
                    </Card>
                </Box>
            )
            }
            { !isAuthenticated && (
                <Text>You are not authenticated</Text>
            )
            }


        </Box>
        
    );
}

export default Accounts;