import React from 'react';
import { Box, Heading, Text } from 'grommet';

const About = () => {
    return (
        <Box>
            <Heading margin={{ top: 'none' }}>About Nada Capital</Heading>
            <Text>We are a company.</Text>
        </Box>
    );
}

export default About;