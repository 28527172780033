import React, { useContext } from 'react';
import {
  Box,
  Button,
  Header,
  Image,
  Menu,
  Nav,
  ResponsiveContext,
  Text,
} from 'grommet';
import { useAuth0 } from "@auth0/auth0-react";
import logo from '../images/Logo-250x82.jpg';

const items = [
  { label: 'Home', loc: "/"},
  { label: 'About', loc: "/about"},
  { label: 'Links', loc: "/links"},
  { label: 'Private', loc: "/private"},

];

const Navigation = () => {
  const size = useContext(ResponsiveContext);
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();


  return (
    <Header round="small" border={{ "color": "#000000", "size": "medium", "style": "solid"}} height="xsmall" fill="horizontal" background={{ "color": "#FFFFFF", "opacity":"strong"}} >
      <Button plain>
        <Box direction="row" align="center" gap="medium">
          <Box direction="row" gap="xsmall">
            <Image src={logo} alt="Logo" />
          </Box>
        </Box>
      </Button>
      <Text>{isAuthenticated ? `User: ${user.name}!` : 'Not logged in!'}</Text>
      
      {size !== 'small' ? (
        <Nav direction="row" key="navItems" >
          {items.map(item => (
              <Button color="#0000FF" label={item.label} key={item.label} href={item.loc} margin="small" />
          ))}
        { !isAuthenticated && (
            <Button color="#0000FF" label={"Login"} key={"Login"} onClick={() => loginWithRedirect()} margin="small" />
          )
        }
        {isAuthenticated && (
            <Button color="#0000FF" label={"Logout"} key={"Logout"} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} margin="small" />
          )
        }
        </Nav>
      ) : (
        <Menu label="Menu" items={items} />
      )}
    </Header>
  );
}

export default Navigation;