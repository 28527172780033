import React from 'react';
import { Box, Heading, Text } from 'grommet';
import { useAuth0 } from "@auth0/auth0-react";

const Private = () => {
    const { user, isAuthenticated } = useAuth0();

    return (

        
        <Box>
            <Heading margin={{ top: 'none' }}>Private Page</Heading>
            { isAuthenticated && (
                <Box>
                    <Text>Only logged in users should see this.</Text>
                    <Text>{user.name}</Text>
                    <Text>{user.email}</Text>
                </Box>
            )
            }
            { !isAuthenticated && (
                <Text>You are not authenticated</Text>
            )
            }


        </Box>
        
    );
}

export default Private;